.crash-view.centered-container {
    box-sizing: border-box;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;

    background-color: #b71c1c;
}

.crash-view.title {
    color: #ffffff;
}

.crash-view.subtitle {
    color: #d3d3d3;
    text-align: center;
    margin-bottom: 32px;
}

.error-icon.fly {
    animation: fly-by 3s linear infinite;
}

@keyframes fly-by {
    0% {
        transform: translate(-35px, -20px);
    }
    25% {
        transform: translate(-10px, -25px);
        animation-timing-function: ease-in-out;
    }
    49% {
        transform: translate(15px, -20px)scale(1,1);
    }
    50% {
        transform: translate(15px, -20px) translate(535px, 0) scale(-1,1);
    }
    75% {
        transform: translate(-10px, -15px) translate(535px, 0) scale(-1,1);
        animation-timing-function: ease-in-out;
    }
    99% {
        transform: translate(-35px, -20px) translate(535px, 0) scale(-1,1);
    }
    100% {
        transform: translate(-35px, -20px);
    }
}
