.bottomBun {
    transform: translateY(-150px);
    opacity: 0;
    animation: fall-down 1s ease-in-out infinite 0s alternate;
  }
  .hamburger {
    transform: translateY(-150px);
    opacity: 0;
    animation: fall-down 1s ease-in-out infinite 0.1s alternate;
  }
  .sallad {
    transform: translateY(-150px);
    opacity: 0;
    animation: fall-down 1s ease-in-out infinite 0.2s alternate;
  }
  .topBun {
    transform: translateY(-150px);
    opacity: 0;
    animation: fall-down 1s ease-in-out infinite 0.3s alternate;
  }
  .flag {
    transform: translateY(-150px);
    opacity: 0;
    animation: fall-down 1s ease-in-out infinite 0.4s alternate;
  }
  @keyframes fall-down {
    0% { 
      transform: translateY(-150px);
      opacity: 0
    }
    30% { 
      transform: translateY(0);
      opacity: 1
    }
    100% {
      transform: translateY(0);
      opacity:1
    }
   
  }